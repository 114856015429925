import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!

  return (_openBlock(), _createBlock(_component_ion_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_toolbar, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_buttons, { slot: "start" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('openMenu')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.menu }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_buttons, { slot: "end" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('showShare', $event)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.shareSocial }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}