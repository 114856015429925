import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        icon: _ctx.personCircleOutline,
        slot: "start",
        color: _ctx.onlineUsers.length <= 0 ? 'medium' : ''
      }, null, 8, ["icon", "color"]),
      _createVNode(_component_ion_label, {
        class: "ion-wrap",
        style: {"white-space":"pre-wrap"},
        color: _ctx.onlineUsers.length <= 0 ? 'medium' : ''
      }, {
        default: _withCtx(() => [
          (_ctx.onlineUsers.length > 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.lang.t('online')) + ": " + _toDisplayString(_ctx.onlineUserString), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.lang.t('online')) + ": " + _toDisplayString(_ctx.lang.t('nobody')), 1)
              ], 64))
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _: 1
  }))
}