
import { defineComponent, onBeforeMount } from "vue";

import { menu, shareSocial, warningOutline } from "ionicons/icons";

import {
  IonPage,
  IonContent,
  IonIcon,
  IonLabel,
  IonChip,
  IonRow,
  IonProgressBar,
  menuController,
  popoverController,
} from "@ionic/vue";

import EventMenu from "./menu/EventMenu.vue";
import DateModule from "../modules/date/DateModule.vue";
import DescriptionModule from "../modules/description/DescriptionModule.vue";
import MapModule from "../modules/map/MapModule.vue";
import ChecklistModule from "../modules/checklist/ChecklistModule.vue";
import VoteModule from "../modules/vote/VoteModule.vue";
import DiscussModule from "../modules/discuss/DiscussModule.vue";
import UserlistModule from "../modules/userlist/UserlistModule.vue";
import MainHeader from "./MainHeader.vue";

import EventService from "../../lib/service/EventService";
import URLShare from "../generic/URLShare.vue";
import WebSocketClientService from "../../net/WebSocketClientService";
import LanguageService from "../../lib/service/LanguageService";
import ContentFilter from "../generic/ContentFilter.vue";

export default defineComponent({
  name: "MainView",
  components: {
    IonPage,
    IonContent,
    IonLabel,
    IonIcon,
    DateModule,
    DescriptionModule,
    MapModule,
    ChecklistModule,
    IonChip,
    IonRow,
    IonProgressBar,
    VoteModule,
    DiscussModule,
    UserlistModule,
    EventMenu,
    MainHeader,
    ContentFilter,
  },
  setup() {
    const lang = LanguageService.getTranslator("main/main");

    const openMenu = () => menuController.open('event');

    const showShare = async (event: Event) => {
      const popover = await popoverController.create({
        component: URLShare,
        componentProps: {
          event: EventService.data.id,
        },
        cssClass: "share-popover",
        event,
      });
      await popover.present();
    };

    onBeforeMount(() => {
      if (location.hash.length > 10) {
        const id = location.hash.substr(1);
        if (id !== EventService.data.id) {
          EventService.join(id);
        }
      }
    });

    return {
      lang,
      EventService,
      WebSocketClientService,
      menu,
      shareSocial,
      warningOutline,
      openMenu,
      showShare
    };
  }
});
