import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_event_module = _resolveComponent("event-module")!

  return (_openBlock(), _createBlock(_component_event_module, { module: "description" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card_content, null, {
        default: _withCtx(() => [
          _createElementVNode("p", { innerHTML: _ctx.text }, null, 8, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}