import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99364458"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subtitle" }
const _hoisted_2 = { class: "menu-footer clickable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_language_selector = _resolveComponent("language-selector")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_content_filter = _resolveComponent("content-filter")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_ctx.EventService.data.event !== undefined)
    ? (_openBlock(), _createBlock(_component_ion_menu, {
        key: 0,
        side: "start",
        "menu-id": "event",
        "content-id": "mainRouter"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { class: "toolbar-title" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.EventService.data.event.static.title) + " ", 1),
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.EventService.data.name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { class: "clickable" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.languageOutline
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("language")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_buttons, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_language_selector)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, {
                    class: "clickable ion-activatable",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logout()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.logOutOutline,
                        slot: "start"
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("logout")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.PlatformService.data.notifications)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        class: "clickable ion-activatable",
                        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleNotifications()), ["stop"]))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.notificationsOutline,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lang.t("notifications")), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_toggle, { checked: _ctx.notificationsEnabled }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_item, {
                    class: "clickable ion-activatable",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleDarkMode()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.contrastOutline
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.lang.t("darkMode")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_toggle, {
                        checked: _ctx.DesignService.design.dark
                      }, null, 8, ["checked"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_content_filter, { mobile: "" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_item, {
                        class: "clickable activatable",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goHome()))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.homeOutline,
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.lang.t("home")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_content_filter, { web: "" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.goHome()))
                    }, _toDisplayString(_ctx.lang.t("home")), 1),
                    _createTextVNode(" | "),
                    _createElementVNode("span", {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showImprint()))
                    }, _toDisplayString(_ctx.lang.t("imprint")), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}