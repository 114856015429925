
import { computed, defineComponent } from "vue";

import { IonCardContent } from "@ionic/vue";

import EventModule from "../EventModule.vue";

import EventService from "../../../lib/service/EventService";

export default defineComponent({
  name: "DescriptionModule",
  components: {
    IonCardContent,
    EventModule,
  },
  setup() {
    const text = computed(() => {
      if (
        EventService.data.event !== undefined &&
        EventService.data.event.static.config.description !== undefined
      ) {
        return EventService.data.event.static.config.description.text.replace(
          /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
          '<a href="https://$&" target="_blank">$&</a>'
        );
      } else {
        return "";
      }
    });
    return {
      EventService,
      text,
    };
  },
});
