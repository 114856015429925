
import { defineComponent } from "vue";

import { personCircleOutline } from "ionicons/icons";

import { IonIcon, IonItem, IonLabel } from "@ionic/vue";

import EventService from "../../../lib/service/EventService";
import LanguageService from "../../../lib/service/LanguageService";

export default defineComponent({
  name: "UserlistModule",
  components: {
    IonIcon,
    IonItem,
    IonLabel,
  },
  computed: {
    onlineUserString(): string {
      return this.onlineUsers.join(", ");
    },
    onlineUsers(): string[] {
      return EventService.data.online.filter(el => el !== '__debug__').map(el => el === EventService.data.name ? `${EventService.data.name} (${this.lang.t('you')})` : el);
    },
  },
  data: () => ({
    lang: LanguageService.getTranslator("module/userlist"),
    personCircleOutline,
  }),
});
