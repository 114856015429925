
import { computed, defineComponent } from "vue";

import {
  person,
  pencil,
  logOutOutline,
  homeOutline,
  languageOutline,
  contrastOutline,
  close,
  notificationsOutline,
} from "ionicons/icons";

import {
  IonMenu,
  IonToolbar,
  IonTitle,
  IonHeader,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
  IonButtons,
  IonToggle,
  modalController,
  menuController,
} from "@ionic/vue";

import EventService from "../../../lib/service/EventService";
import LanguageService from "../../../lib/service/LanguageService";
import LanguageSelector from "../../generic/LanguageSelector.vue";
import ImprintModal from "../../generic/modals/ImprintModal.vue";
import DesignService from "../../../lib/service/DesignService";
import ContentFilter from "../../generic/ContentFilter.vue";
import PlatformService from "../../../lib/service/PlatformService";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "EventMenu",
  components: {
    IonMenu,
    IonToolbar,
    IonTitle,
    IonHeader,
    IonContent,
    IonList,
    IonIcon,
    IonItem,
    IonLabel,
    IonButtons,
    IonToggle,
    LanguageSelector,
    ContentFilter,
  },
  setup() {
    const lang = LanguageService.getTranslator("main/menu");
    const router = useRouter();
    
    const notificationsEnabled = computed(() => {
      return (
        EventService.data.auth !== undefined &&
        EventService.data.auth.notifications
      );
    });

    const goHome = () => {
      menuController.close();
      router.push("/home");
    };

    const dismiss = () => menuController.close();

    const showImprint = async () => {
      const modal = await modalController.create({
        component: ImprintModal,
      });
      await modal.present();
    };

    const logout = () => EventService.logout();

    const toggleDarkMode = () => {
      DesignService.setDarkMode(!DesignService.design.dark);
    };
    
    const toggleNotifications = () => {
      EventService.toggleNotifications(!notificationsEnabled.value);
    };

    return {
      lang,
      EventService,
      DesignService,
      PlatformService,
      person,
      pencil,
      languageOutline,
      contrastOutline,
      logOutOutline,
      homeOutline,
      close,
      notificationsOutline,
      goHome,
      dismiss,
      showImprint,
      logout,
      toggleDarkMode,
      toggleNotifications
    };
  }
});
