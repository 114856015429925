
import { defineComponent } from "vue";

import {shareSocial, menu} from "ionicons/icons";

import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonIcon,
  IonTitle,
  IonButton
} from "@ionic/vue";

export default defineComponent({
  name: "MainHeader",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonIcon,
    IonTitle,
    IonButton
  },
  props: {
      title: {
          type: String,
          default: ''
      }
  },
  setup() {
      return {
          shareSocial,
          menu
      }
  }
});
